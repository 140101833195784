import * as React from 'react'
import { Option } from '../components/page'
import { Category } from './types'

export const locations = [
  'Chico',
  'Centro',
  'Restrepo',
  'Rosales',
  'Teusaquillo',
  'Chapinero',
  'Soacha',
  'Salitre',
  'Modelia',
  'Zona norte de la capital',
]

export const servicesInLocations = (input: string[]): string[] => {
  return input.reduce((acc, curr) => {
    return [...acc, ...locations.map(location => `${curr} en ${location}`)]
  }, [] as string[])
}

export const categoriesInLocations = (input: Category[]): string[] => {
  return servicesInLocations(
    input.reduce((acc, curr): string[] => {
      const servicesList = curr.services.map(ser => ser.name)
      return [...acc, ...servicesList]
    }, [] as string[]),
  )
}

export const LocationSplitList: React.FC<{ columns: number }> = ({
  columns,
}) => {
  const percentage = React.useMemo(() => {
    const items = 100 / columns
    return `${items}%`
  }, [columns])

  const content = React.useMemo(() => {
    const chunks = []
    const items = Math.ceil(locations.length / columns)

    let toAppend: string[] = []
    locations.forEach(location => {
      toAppend.push(location)

      if (toAppend.length >= items) {
        chunks.push([...toAppend])
        toAppend = []
      }
    })

    if (toAppend.length > 0) {
      chunks.push([...toAppend])
    }

    return chunks
  }, [columns])

  return (
    <div role="list" className={'flex'}>
      {content.map((column, index) => (
        <div key={index} style={{ width: percentage }}>
          {column.map((location, kindex) => (
            <Option key={`${index}:${kindex}`}>
              <b>{location}</b>.
            </Option>
          ))}
        </div>
      ))}
    </div>
  )
}
