import * as React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/default-layout'
import { SEO, buildCatalogLDSJSON } from '../components/seo'
import { SectionOne, SectionTwo, Contact } from '../components/page'
import { ProductsSection } from '../components/product-section'
import { MainImage } from '../components/image'
import { usePlumberImages } from '../components/plumber-image'
import { SpecificLogo } from '../components/logo'
import { CatalogComponent } from '../components/catalog'
import { LocationSplitList } from '../components/locations'
import { getCatalog } from '../data/plumber-catalog'
import { Slider } from '../components/slider'

export const Head = () => {
  const images = usePlumberImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])

  return (
    <SEO
      article={false}
      title={'Plomero a domicilio'}
      description={
        'Plomero o Fontanero a domicilios las 24 horas del día en Bogotá. Filtraciones, destape de cañerias, aumento de presión, lavado tanques, reparaciónes.'
      }
    >
      <script
        type="application/ld+json"
        children={buildCatalogLDSJSON('Plumber', {
          name: 'Domicilios 24/7',
          categories: catalog.categories,
        })}
      />
    </SEO>
  )
}

const Plumbers: React.FC<PageProps> = () => {
  const images = usePlumberImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])

  return (
    <Layout
      theme={'teal'}
      visible={{ plumber: true }}
      logo={<SpecificLogo>Plomería</SpecificLogo>}
    >
      <Slider
        id={'inicio'}
        business={'Promería profesional'}
        wallpaper={'plumberSlider'}
        overlay={0.8}
        pageTitle={'Servicio de Plomería 24/7'}
        subTitle={'Plomero las 24 horas de día'}
        pageDescription={
          <>
            Servicio de plomero o fontanero a domicilio las 24 horas de día en
            Bogotá, destapes de cañerias, instalación de duchas eléctricas,
            instalación de grifería, sistema de sonda eléctrica y manual,
            instalación de calentadores eléctricos y a gas, reparación de
            calentadores eléctricos y a gas, reparación de tuberías, sistema de
            geofono digital, reparación e instalación de estufas, etc.
          </>
        }
        goTo={'#servicios'}
      />

      <ProductsSection
        id={'servicios'}
        pageTitle={'Nuestros servicios'}
        business={'Plomería Domicilios 24 horas en Bogotá'}
        businessType={'Plumber'}
        pageSubTitle={
          'Estos son los servicios más populares con los cuales contamos'
        }
        products={<CatalogComponent light catalog={catalog} />}
      />

      <SectionOne
        id={'nosotros'}
        pageTitle={
          'Somos profecionales certificados que trabajan bajo altos estandares de calidad.'
        }
        image={<MainImage alt={'Plomero'} reference={'plumber'} />}
        description={
          <>
            <p>
              Contamos con una basta trayectoria de mas de <b>8 años</b> donde
              nuestro clientes siempre han estado satisfechos, debido a la
              calidad del servicio que prestan nuestros profesionales.
            </p>
            <p>
              Las 24 del día, 7 días a la semana. En caso de que tengas un
              emergencia no te preocupes nos movilizamos hasta el lugar que te
              encuentres. Ofrecemos distintos servicio al mejor precio y con
              exelente calidad en el sector empresarial, residencial.
            </p>
          </>
        }
      />

      <SectionTwo
        id={'cobertura'}
        image={
          <MainImage
            alt={'Desplazamiento en vehículo'}
            reference={'plumberDesign'}
            className={'rounded'}
          />
        }
        pageTitle={'Cuentanos tu problema, nosotros hacemos el resto.'}
        description={
          <>
            <p>
              Disponemos de varias sedes distribuidas en toda{' '}
              <strong>Bogotá</strong>, nuestros profesionales disponen de
              transporte optimo para desplazarce al lugar que desees con las
              herramientas necesarias. Estos son algunos de los lugares donde
              contamos con más solicitudes de servicios:
            </p>
            <LocationSplitList columns={2} />
          </>
        }
      />

      <Contact
        id={'contacto'}
        business={'Plumber'}
        businessName={'Plomería'}
        priceRange={'$20.000 - $400.000'}
      />
    </Layout>
  )
}

export default Plumbers
