import * as React from 'react'
import { ProductsWrapper } from './products'
import { CallButton } from './call-button'
import { DefaultContainer } from './containers'

interface ProductsSectionProps extends React.HTMLProps<HTMLDivElement> {
  pageTitle: React.ReactNode
  pageSubTitle: React.ReactNode
  products: React.ReactNode
  business: string
  businessType: string
}

export const ProductsSection: React.FC<ProductsSectionProps> = ({
  pageTitle,
  business,
  businessType,
  pageSubTitle,
  products,
  ...props
}) => {
  return (
    <section className={'relative w-full py-20 text-center'} {...props}>
      <div className={'relative z-10'}>
        <DefaultContainer>
          <h2 className={'text-4xl font-medium mb-3'}>{pageTitle}</h2>
          <p className={'mb-14'}>{pageSubTitle}</p>

          <ProductsWrapper>{products}</ProductsWrapper>

          <div className={'mt-14'}>
            <CallButton action={'services-box'}>Llamar</CallButton>
          </div>
        </DefaultContainer>
      </div>
    </section>
  )
}
